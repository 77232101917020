.Toastify__toast-container {
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 0;
}

.Toastify__toast {
  $icon-size: 23px;
  $close-icon-size: 14px;
  $content-width: 269px;

  display: flex;
  align-items: center;
  gap: $spacer-5;
  width: 100%;
  padding: $spacer-4;
  border-left: 3px solid;
  font-family: $montserrat-medium;
  box-shadow: unset;

  .toast-content {
    display: flex;
    align-items: center;
    gap: $spacer-5;

    .toast-content-icon {
      height: $icon-size;
      width: $icon-size;

      &--close {
        &, img {
          height: $close-icon-size;
          width: $close-icon-size;
        }
      }
    }

    .toast-content-text {
      width: $content-width;
      font-size: $font-size-h3;
    }
  }

  .Toastify__close-button {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  &--success {
    background: $medi24-light-green;
    border-color: $medi24-dark-green;
    color: $medi24-dark-green;
  }

  &--error {
    background: $medi24-light-pink;
    border-color: $medi24-pink;
    color: $medi24-pink;
  }
}

@include mobile-sm-only {
  .Toastify__toast-container {
    width: 100%;

    .Toastify__toast {
      .toast-content {
        .toast-content-text {
          width: unset;
          flex: 1;
        }
      }
    }
  }
}

@include mobile-only {
  .Toastify__toast-container--top-right, .Toastify__toast-container--bottom-center {
    top: calc(#{$header-height} + #{$spacer-4});
  }

  .Toastify__toast-container--bottom-center {
    top: unset;
    bottom: 15rem; // the height of the pop-in footer + 1 rem
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 $spacer-1;
    transform: translateX(0);

    .Toastify__toast {
      width: fit-content;
    }
  }
}

@include tablet-only {
  .Toastify__toast-container--top-right {
    top: 9rem; // the height of the header + navigation menu
  }
}

@include desktop-only {
  .Toastify__toast-container--top-right {
    top: 12rem; // the height of the header + navigation menu
    right: 0;
    padding: 0 $spacer-6; // Padding for screen smaller than the app size
  }
}

@include max-width-only {
  .Toastify__toast-container--top-right {
    padding: 0;
    right: calc((100% - #{$app-max-width}) / 2);
  }
}
