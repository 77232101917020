.display-time {
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    display: flex;
    align-items: center;
    font-size: 21px;
    margin: 0 0 0.5rem -30px;
    i {
      width: 20px;
      margin: 0 0.7rem;
    }
  }
}
