.event {
  --event-height: 175px;
  --illustration-width: 100px;
  --countdown-icon-size: 12px;
  --countdown-bar-size: 3.5px;

  position: relative;
  display: flex;
  align-items: center;
  height: var(--event-height);
  border-radius: $border-radius-s;
  border: 1px solid $medi24-light-pink;
  background: $white;

  .illustration {
    height: 100%;
    width: var(--illustration-width);
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    .illustration-img {
      height: 100%;
      width: 100%;
      border-radius: inherit;
      object-fit: cover;
    }

    .attachment-icon {
      position: absolute;
      // valeurs exactes par rapport à la taille du svg
      top: -9px;
      left: 12px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 0;
    align-self: stretch;
    padding: $spacer-4 !important;

    .content-infos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacer-4;
      align-self: stretch;

      .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        gap: $spacer-2;

        .content-header-group {
          display: flex;
          flex-direction: column;
          gap: $spacer-1;
        }

        .content-header-title, .content-header-subtitle {
          all: unset;
          color: $medi24-label-color;
          font-size: $font-size-h6;
          font-family: $montserrat-bold;
          line-height: normal;
        }

        .content-header-subtitle {
          font-family: $montserrat;
        }
      }

      .content-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacer-2;
        align-self: stretch;

        .content-body-title, .content-body-subtitle {
          all: unset;
          color: $medi24-label-color;
          font-family: $montserrat;
          line-height: normal;
        }

        .content-body-title {
          font-size: $font-size-h6;
        }

        .content-body-subtitle {
          font-size: $font-size-smaller;
        }

        .content-body-section {
          display: flex;
          flex-direction: column;
          gap: $spacer-1;
          width: 100%;
          padding-top: $spacer-2;
          border-top: 1px solid $medi24-light-pink;

          .content-body-section-title, .content-body-section-subtitle {
            margin: 0;
            padding: 0;
            color: $medi24-label-color;
            font-size: $font-size-smaller;
            font-family: $montserrat;
            line-height: normal;
            text-transform: uppercase;
          }

          .content-body-section-subtitle {
            font-family: $montserrat-bold;
          }
        }
      }
    }

    .countdown-bar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacer-2;
      align-self: stretch;
      color: $medi24-primary;

      .countdown {
        display: flex;
        align-items: center;
        gap: $spacer-1;
        font-size: $font-size-smaller;
        font-family: $montserrat-bold;
        line-height: normal;

        .countdown-icon {
          height: var(--countdown-icon-size);
          width: var(--countdown-icon-size);
        }
      }

      .bar {
        height: var(--countdown-bar-size);
        width: 100%;
        border: none;
        border-radius: $border-radius-xs;
        background-color: $medi24-gray-6;

        /* for chrome & safari*/
        &::-webkit-progress-bar {
          background-color: $medi24-gray-6;
        }

        &::-webkit-progress-value {
          border-radius: $border-radius-xs;
          background-color: $medi24-primary;
        }

        // if we try to combine the styles, eg: .a, .b {...}, we'll lose the progress bar style
        /* firefox */
        &::-moz-progress-bar {
          border-radius: $border-radius-xs;
          background-color: $medi24-primary;
        }
      }

      &--positive {
        color: $medi24-green;

        .bar{
          /* for chrome & safari*/
          &::-webkit-progress-value {
            background-color: $medi24-green;
          }

          /* firefox */
          &::-moz-progress-bar {
            background-color: $medi24-green;
          }
        }
      }
    }
  }

  .hidden-button {
    all: unset;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    cursor: pointer;
  }

  &--consultation {
    height: auto;

    .illustration {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $medi24-lighter-pink;

      .illustration-img {
        height: auto;
        width: 100%;
      }
    }
  }
}

@include tablet-only {
  .event {
    --event-height: 200px;
    --illustration-width: 110px;

    .content {
      padding: $spacer-5 $spacer-4 !important;
    }
  }
}

@include desktop-only {
  .event {
    --event-height: 170px;
    --illustration-width: 120px;

    .content {
      .content-infos {
        .content-header {
          .content-header-title, .content-header-subtitle {
            font-size: $font-size-h2;
          }
        }

        .content-body {
          .content-body-title {
            font-size: $font-size-h2;
          }

          .content-body-subtitle {
            font-size: $font-size-h6;
          }
        }
      }
    }
  }
}