.sensely-loader {
  .sensely-loader-text{
    text-align: center;
    width: 70%;
    margin: 0 auto;

  }
  .footer{
    margin-top: 2rem;
  }
}

.sensely {
  .action-tile {
    margin-left: 0;
    margin-bottom: 1rem;
    width: 100%;
    margin-right: 0;
  }

  .tile-container {
    .hotline-container {
      width: 100%;
    }
  }
}

.sensely-container {
  .page-container {
    min-height: 400px;
  }
}

.sensely-widget {
  overflow: unset;
}

.title-sensely {
  margin: 30px 0 30px;
  font-size: 18px;
}

.sensely-result-footer {
  font-size: 18px;
  text-align: unset;
  margin-top: 30px;
  margin-bottom: 80px;
}


