.registration-detail-block {
  margin-top: 36px;

  .registration-detail-description {
    color: $medi24-gray-4;
    background-color: $medi24-gray-5;
    border: 1px solid $medi24-gray-3;

    .registration-detail-description-text {
      padding: 11px;
      min-height: 132px;
    }
  }
}
