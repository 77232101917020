.checkbox-group {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    input {
        margin-right: 10px;
    }

    .hidden-button {
        position: absolute;
        inset: 0;
        width: 100%;
        border: unset;
        background-color: transparent;
        -webkit-tap-highlight-color: transparent;
      }

      input[type=checkbox] {
        opacity: 0;
      }

      .tick {
        --tick-size: 21.45px; // to match the icon's default size

        position: absolute;
        left: 0;
        color: $medi24-primary;

        &--empty {
          height: var(--tick-size);
          width: var(--tick-size);
          border: 1px solid $medi24-label-color;
          border-radius: $border-radius-s;
        }
      }
}

.small-button {
    text-transform: uppercase;
    padding: 5px;
    background-color: white;
    border: 1px solid $medi24-secondary;
    &.primary{
        border: 1px solid $medi24-primary;
        justify-self: end;
        max-width: 150px;
    }
  }

.button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacer-4;
    width: 100%;
}

.small-button-group {
    display: flex;
    flex-direction: column-reverse;

    &.detail {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 20px;
    }
}


@include tablet-only {
    .small-button-group {
        display: flex;
        justify-content: flex-end;
        flex-direction: unset;
        button {
            width: fit-content;
            padding: 20px;
            height: 45px;
            font-size: 14px;
        }

        &.detail {
            flex-direction: unset;
            justify-content: space-between;
        }
    }

    .button-group {
        flex-direction: row;
        justify-content: center;
        gap: $spacer-6;
    }
}

.button {
    --button-width: 350px;
    --button-height: 59px;
    --button-icon-size: 23px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacer-2;
    height: var(--button-height);
    width: var(--button-width);
    max-width: 100%;
    margin: 0;
    padding: 0 $spacer-4;
    color: $medi24-primary;
    background-color: white;
    -webkit-tap-highlight-color: white;
    border-radius: 4px;
    border: 1px solid $medi24-primary;

    .button-content {
        font-family: $montserrat-bold;
        font-size: $font-size-h3;
    }

    .button-icon {
        height: var(--button-icon-size);
        width: var(--button-icon-size);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &:hover {
        color: $medi24-primary-hover;
        border-color: $medi24-primary-hover;
    }

    &:disabled {
        color: $medi24-palepink;
        border-color: $medi24-palepink;
    }

    &--primary {
        color: white;
        background-color: $medi24-primary;
        border: unset;

        &:hover {
            color: white;
            background-color: $medi24-primary-hover;
        }

        &:disabled {
            color: $medi24-palepink;
            background-color: $medi24-palepink;
        }
    }

    &--icon-only, &--text {
        padding: 0;
        border: unset;
    }

    &--icon-only {
        height: var(--button-icon-size);
        width: var(--button-icon-size);
        border-radius: 100%;
    }

    &--text {
        height: unset;
        width: unset;
        border-radius: unset;
    }
}

@include tablet-only {
    .button {
        --button-width: 310px;
    }
}
