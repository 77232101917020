@mixin desktop-only {
  @media screen and (min-width: $desktop-width) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $ipad-width) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: calc(#{$ipad-width} - 1px)) {
    @content;
  }
}

@mixin mobile-sm-only {
  @media screen and (max-width: $small-phone-width) {
    @content;
  }
}

@mixin max-width-only {
  @media screen and (min-width: $app-max-width) {
    @content;
  }
}

@mixin structuredPage($classPrefix) {
  $choice-input-size: 16px;

  .#{$classPrefix}-page {
    display: flex;
    flex-direction: column;
    gap: $spacer-6-5;
    width: 100%;

    .#{$classPrefix}-page-title,
    .#{$classPrefix}-page-subtitle,
    .#{$classPrefix}-page-text {
      padding: 0;
      margin: 0;
    }

    .#{$classPrefix}-page-title {
      color: $medi24-primary;
      font-family: $montserrat-extra-bold;
      font-size: $font-size-h1;
      line-height: normal;
    }

    .#{$classPrefix}-page-subtitle,
    .#{$classPrefix}-page-text,
    .#{$classPrefix}-page-caption {
      color: $medi24-label-color;
      font-family: $montserrat-medium;
      font-size: $font-size-h3;
      line-height: 150%;
    }

    .#{$classPrefix}-page-subtitle {
      font-family: $montserrat-bold;
    }

    .#{$classPrefix}-page-caption {
      text-align: center;
      font-size: $font-size-small;
    }

    .#{$classPrefix}-page-header {
      display: flex;
      flex-direction: column;
      gap: $spacer-6;
      width: 100%;

      .#{$classPrefix}-page-subtitle-container {
        display: flex;
        align-items: center;
        gap: $spacer-2;
      }
    }

    .#{$classPrefix}-page-separator {
      border: 1px solid $medi24-light-pink;
    }

    .button-group {
      align-items: center;
    }

    .#{$classPrefix}-inputs {
      display: flex;
      flex-direction: column;
      gap: $spacer-4;
    }

    .#{$classPrefix}-input-group {
      display: flex;
      flex-direction: column;
      gap: $spacer-4;
      width: 100%;
    }

    .#{$classPrefix}-data-label {
      font-size: $font-size-small;
      color: $medi24-label-color;
    }

    .#{$classPrefix}-data-value {
      display: flex;
      align-items: flex-end;
      min-height: calc(#{$font-size-h3} * 1.5); // allows us to align with "data-label"
      font-family: $montserrat-medium;
      font-size: $font-size-h3;
      color: $medi24-label-color;
      line-height: 1;
    }

    .#{$classPrefix}-data-value-group {
      display: flex;
      flex-direction: column;
      gap: $spacer-1;

      .#{$classPrefix}-data-value {
        &:not(.#{$classPrefix}-data-value:first-child) {
          min-height: auto;
        }
      }
    }

    .#{$classPrefix}-input {
      padding: $spacer-4 $spacer-3;
      font-family: $montserrat-medium;
      border-radius: $border-radius-s;
      outline: unset;
      border: 1px solid $medi24-gray-7;

      .MuiInputBase-root {
        &, input, .MuiOutlinedInput-notchedOutline {
          padding: 0;
          font-family: inherit;
          border-radius: unset;
          outline: inherit;
          border: 0;
        }
      }
    }

    .#{$classPrefix}-page-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .#{$classPrefix}-data-value {
        display: inline-block;
        max-width: 50%;
        min-height: unset;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .#{$classPrefix}-page-group {
      display: flex;
      flex-direction: column;
      gap: $spacer-5;
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .#{$classPrefix}-page-choices {
      display: flex;
      flex-direction: column;
      gap: $spacer-4;

      .choice-group {
        position: relative;
        display: flex;
        align-items: center;
        gap: $spacer-4;
        width: fit-content;

        .outer-input {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: $choice-input-size;
          width: $choice-input-size;
          border: 1px solid $medi24-label-color;
          border-radius: 100%;

          &::after {
            content: '';
            position: absolute;
            height: calc(#{$choice-input-size} * 0.5);
            width: calc(#{$choice-input-size} * 0.5);
            background-color: $white;
            border-radius: 100%;
            transition: 150ms ease-in-out;
          }

          &[data-checked=true] {
            &::after {
              background-color: $medi24-label-color;
            }
          }
        }

        .hidden-button {
          position: absolute;
          inset: 0;
          width: 100%;
          border: unset;
          background-color: transparent;
          -webkit-tap-highlight-color: transparent;
          border: 0;
        }
      }
    }

    .#{$classPrefix}-page-data-container {
      display: flex;
      gap: $spacer-4;
      width: max-content;
    }

    .#{$classPrefix}-page-data-group {
      display: flex;
      flex-direction: column;
      gap: $spacer-5;
      width: auto;

      .#{$classPrefix}-data-label {
        display: flex;
        align-items: flex-end;
        height: calc(#{$font-size-h3} * 1.5); // allows us to align with "profile-data-value"
      }
    }
  }

  @include tablet-only {
    .#{$classPrefix}-page {
      $choice-input-size: 18px;

      .#{$classPrefix}-page-header {
        gap: $spacer-6;
      }

      .#{$classPrefix}-inputs {
        flex-direction: row;
      }

      .#{$classPrefix}-page-choices {
        .choice-group {
          .outer-input {
            height: $choice-input-size;
            width: $choice-input-size;

            &::after {
              height: calc(#{$choice-input-size} * 0.5);
              width: calc(#{$choice-input-size} * 0.5);
            }
          }
        }
      }

      .#{$classPrefix}-page-data-container {
        gap: $spacer-6;
      }

      .#{$classPrefix}-page-action {
        &--mobile-only {
          .button {
            display: none;
          }
        }
      }
    }
  }

  @include desktop-only {
    .#{$classPrefix}-page {
      gap: $spacer-6-5;

      .#{$classPrefix}-page-header {
        gap: $spacer-6-5;
      }

      .#{$classPrefix}-data-label {
        font-size: $font-size-h3;
      }

      .#{$classPrefix}-data-value {
        min-height: calc(#{$font-size-h2} * 1.5); // allows us to align with "data-label"
        font-size: $font-size-h2;
      }

      .#{$classPrefix}-page-data-container {
        gap: $spacer-9;
      }

      .#{$classPrefix}-page-data-group {
        gap: $spacer-4;

        .#{$classPrefix}-data-label {
          height: calc(#{$font-size-h2} * 1.5); // allows us to align with "profile-data-value"
        }
      }
    }
  }
}