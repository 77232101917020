.card{
  text-align: start;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.clickable{
    box-shadow: 0px 3px 6px #00000029;
    background-color: $medi24-yellow;
  }
  &.unclickable{
    cursor: auto;
    &.yellow{
      background-color: rgba(red($medi24-yellow), green($medi24-yellow), blue($medi24-yellow), 0.5);
    }
    &.pink{
      background-color: rgba(red($medi24-pink), green($medi24-pink), blue($medi24-pink), 0.1);
    }
    &.green{
      background-color: rgba(red($medi24-green-2), green($medi24-green-2), blue($medi24-green-2), 0.2);
    }
  }
  .card-content {
    width: 100%;
    padding: 19px;
    position: relative;

    .extended {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0.4rem;
      margin-bottom: 0px;
      font-size: 12px;
    }

    &.callback {
      padding-bottom: 30px;
    }

    .card-date-box {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      .card-date-first-group{
        flex-grow: 1;
        .card-date-text {
          color : $medi24-secondary;
          font-size: 12px;
          text-align: justify;
          text-transform: uppercase;
          letter-spacing: -0.03rem;
          padding-bottom: 5px;
        }
        .card-date-title {
          color: $medi24-secondary;
          font-size: 21px;
          font-weight: bold;
        }
      }

      .card-date-second-group{
        width: 40%;
        .card-date-text {
          color : $medi24-secondary;
          font-size: 12px;
          text-align: justify;
          text-transform: uppercase;
          letter-spacing: -0.03rem;
          padding-bottom: 5px;
        }
        .card-date-title {
          color: $medi24-secondary;
          font-size: 21px;
          font-weight: bold;
        }
        .card-date-expiration {
          color: $medi24-secondary;
          font-size: 11px;
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: center;
          .card-date-expiration-icon {
            margin-right: 2px;
          }
        }
      }
    }
    .card-date-box:first-child{
      padding-top: 0px;
    }
  }
}

@include desktop-only {
  .card{
    max-width: 31%;
  }
}
