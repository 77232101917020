.badge {
  $icon-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacer-1 $spacer-3;
  gap: $spacer-2;
  background-color: $medi24-primary;
  color: #FFF;
  font-size: $font-size-smaller;
  font-family: $montserrat-bold;
  line-height: normal;
  border-radius: $border-radius-md;

  &--primary {
    background-color: $medi24-green;
  }

  &--primary-outlined {
    background-color: unset;
    border: 1px solid $medi24-green;
    color: $medi24-green;
  }

  &--secondary-outlined {
    background-color: unset;
    border: 1px solid $medi24-primary;
    color: $medi24-primary;
  }

  svg {
    height: $icon-size;
    width: $icon-size;
  }
}


@include desktop-only {
  .badge {
    font-size: $font-size-small;
  }
}