.consultation-slider-container {
  overflow-x: hidden;
  min-height: 150px;
}

.consultation-details {
  .medication::before{
    content: "•";
    font-weight: 900;
    font-size: 20px;
    padding-right: 5px;
    position: relative;
    bottom: -2px;
    color: $medi24-primary
  }

  .consultation-details-block {
    h2{
      margin-bottom: 6px;
    }
    margin-bottom: 38px;
    &.first {
      margin-top: 10px;
    }
  }
}
.display-time {
  align-items: flex-start;
  .display-time-container {
    display: flex;
    flex-direction: row;

    .line {
      font-size: 14px;
      margin: 0;
      font-weight: bold;
      color: $gray-medium;

      i {
        margin: 0 0.3rem;
        width: unset;
        font-weight: 400;
      }
    }
  }
}

.appt-content-block {
  border-bottom: 1px solid $gray-medium-light;
}

.consultation-content {

  .next-previous-event {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    color: $medi24-primary;
    .arrow-group {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      &.disabled {
        cursor: unset;
        color: $gray-medium-light;
      }
    }

    .arrow-consultation {
      font-size: 20px;
      padding: 5px;
    }
  }

  .consultation-header {
    .page-title {
      display: flex;
      font-size: 24px;
      .far.fa-calendar-plus {
        margin-left: 20px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  .consultation-content-block {
    margin-top: 20px;
    border-bottom: 1px solid $gray-medium-light;

    .block-container {
      &.appt {
        &.detail {
          border-bottom: none;
          .fas.fa-external-link-alt {
            margin-left: 20px;
          }
        }
      }
    }

    .block-content {
      margin-top: 6px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .appointmentRelease-block {
        h4 {
          margin:0;
        }
      }

      .callback-detail-block {
        margin-top: 10px;
        .card-date-group {
          display: flex;
          justify-content: space-between;
          width: 170px;
          font-weight: bold;
        }
          .card-date-group-small {
            font-size: 10px;
            font-weight: normal;
            width: 120px;
            justify-content: space-between;
            display: flex;
        }

        .card-date-expiration {
          color: $gray-medium-light;
          font-size: 10px;
          i{
            margin-right: 5px;
          }
        }
      }
      .description-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        .registration-detail {
          background-color: #F9F9F9;
          border: 1px solid $medi24-secondary;
          min-height: 100px;
          padding: 10px;
          white-space: pre-line;
        }
      }
      .small-description {
        font-size: 10px;
        font-weight: normal;
      }
    }

    @include tablet-only {
      .block-content{
        .callback-detail-block {
          margin-right: 30%;
        }
      }
    }
  }

  .consultation-content-block:last-child {
    border-bottom: none;
  }
}

