@include structuredPage(welcome);

.welcome-page {
  .welcome-page-hero-image {
    --circle-size: 184px;
    --illustration-height: 182px;
    --illustration-width: 220px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      height: var(--illustration-height);
      width: var(--illustration-width);
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      height: var(--circle-size);
      width: var(--circle-size);
      background-color: $medi24-light-pink;
      border-radius: 100%;
      z-index: $bottom-layout;
    }
  }
}


@include tablet-only {
  .welcome-page {
    .welcome-page-hero-image {
      --circle-size: 190px;
      --illustration-height: 190px;
      --illustration-width: 230px;
    }
  }
}

@include desktop-only {
  .welcome-page {
    .welcome-page-hero-image {
      --circle-size: 280px;
      --illustration-height: 285px;
      --illustration-width: 295px;
    }
  }
}
