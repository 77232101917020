.rdt{
  flex-grow: 1;
  display: flex;

  div:first-child {
    display: flex;
    flex-grow: 1;
  }

    table {
      border-spacing: 0;

      .dow {
        background-color: $medi24-yellow;
      }

      .dow:first-child {
        color: $medi24-blue;
      }

      .dow:last-child {
        color: $medi24-blue;
      }

      tbody {
        .rdtOld {
          color: $medi24-secondary;
          opacity: 50%;
        }

        .rdtActive {
          color: white;
          background-color: $medi24-primary;
          opacity: 1;
        }
      }
    }
}
