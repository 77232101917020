.empty-state {
  --image-height: 86px;
  --image-width: 101px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacer-6-5;
  width: 100%;
  padding: 0 $spacer-6-5;

  .empty-state-illustration {
    height: var(--image-height);
    width: var(--image-width);
  }

  .empty-state-content {
    font-family: $montserrat-medium;
    text-align: center;
    color: $medi24-label-color;
  }
}

@include tablet-only {
  .empty-state {
    --image-height: 185px;
    --image-width: 200px;
  }
}
