.header{
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: white;
  z-index: 4;

  .header-logo {
    width: 127px;
    height: 30px;
  }
}

@include tablet-only {
  .header-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: $app-max-width;
    padding: $spacer-4 $spacer-6 0;

    .header, .menu {
      position: relative;
      width: fit-content;
      padding: 0;
    }
  }

  .header{
    justify-content: start;
    height: fit-content;
  }
}

@include desktop-only {
  .header-container {
    padding: $spacer-7 $spacer-6 0;
  }
}

@include max-width-only {
  .header-container {
    padding: $spacer-7 0 0;
  }
}
