.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: $spacer-5;
  width: 100%;
  // to avoid overlapping with the navigation menu, we'll add its height to the bottom padding
  padding: $spacer-6 0 calc(#{$spacer-6} + #{$mobile-nav-menu-height});
  background-color: $medi24-light-pink;

  .footer-copyright, .footer-link {
    color: $medi24-label-color;
    text-align: center;
    font-size: $font-size-h6;
    font-style: normal;
    font-family: $montserrat;
    line-height: 128%;
  }

  .footer-link-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: inherit;
    width: 100%;

    .footer-link {
      color: $medi24-primary;
      font-family: $montserrat-medium;
    }
  }
}

@include tablet-only {
  .footer {
    justify-content: flex-start;
    flex: unset;
    gap: $spacer-4;
    padding: $spacer-4 $spacer-6;

    .footer-link-container {
      flex-direction: row;
      justify-content: center;
      gap: $spacer-4;
      max-width: $app-max-width;
    }
  }
}

@include desktop-only {
  .footer {
    gap: $spacer-8;
    padding: $spacer-7 0;

    .footer-link-container {
      padding: 0 $spacer-6;
      justify-content: space-between;
    }
  }
}

@include max-width-only {
  .footer {
    .footer-link-container {
      padding: 0;
    }
  }
}