h1 {
  font-weight: bold;
  color: $medi24-primary;
  font-size: 32px;
  letter-spacing: -.12pt;
  margin: 0;

  &.spaced {
    margin-bottom: 30px;
  }
}

h2 {
  font-weight: bold;
  color: $medi24-secondary;
  font-size: 24px;
  letter-spacing: -.12pt;
  margin: 0;

  &.modal-title {
    color: $medi24-primary;
  }
}

h3 {
  color: $medi24-secondary;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

a {
  color: $medi24-primary;
  font-weight: 800;
}

.classic-text {
  white-space: pre-line;
  color: #575757;
  font-size: 16px;
  text-align: justify;

  &.grey {
    color: $medi24-secondary;
  }

  &.bold {
    font-weight: bold;
    font-size: 18px;
  }

  &.light {
    color: $gray-medium-light;
    font-size: 18px;
    text-align: unset;
    -webkit-font-smoothing: antialiased;
  }
}

.small-text {
  font-size: 14px;
  color: $medi24-secondary;
  margin-bottom: 5px;

  &.appt {
    text-align: center;
  }
}

.big-text {
  font-size: 16px;
  color: $medi24-secondary;
  margin-bottom: 5px;
}


.text-small-button {
  color: $medi24-secondary;
  font-size: 11px;
  font-weight: bold;

  &.primary {
    color: $medi24-primary;
  }
}

.error-text {
  font-size: 12px;
  color: red;
}

.call-link {
  color: $medi24-primary;
  text-decoration: underline;
}

@include desktop-only {
  .error-text {
    font-size: 12px;
    color: red;
    text-align: left;
  }
}

.MuiPaper-root {
  font-family: $montserrat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  font-weight: normal;
}
