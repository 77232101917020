.MuiDialog-root {
  --close-icon-size: 40px;

  margin: 0;
  padding: 0;

  .MuiDialog-container {
    align-items: flex-end;
    width: 100%;

    .MuiPaper-root {
      height: 100%;
      width: 100%;
      max-width: 100%;
      padding: $spacer-6 0 0;
      margin: 0;
      border-radius: $border-radius-xxl;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .MuiDialogTitle-root,
      .MuiDialogContent-root,
      .MuiDialogActions-root {
        margin: 0;
        padding: 0 $spacer-6-5;
      }

      .MuiDialogTitle-root {
        display: flex;
        align-items: center;
        padding: 0 calc(var(--close-icon-size) + #{$spacer-6-5}) 0 $spacer-6-5;

        .MuiTypography-root {
          all: unset;
        }

        .modal-title {
          font-size: $font-size-h1;
          font-family: $montserrat-extra-bold;
          color: $medi24-primary;
        }
      }

      .MuiDialogContent-root {
        padding: 0 $spacer-6-5 $spacer-7;
        margin: $spacer-7 0 0;

        .classic-text {
          font-family: $montserrat-medium;
          font-size: $font-size-h3;
          color: $medi24-label-color;
          line-height: 22px;
        }

        .lds-div {
          margin: 0;

          .lds-spinner {
            overflow: hidden;
          }
        }
      }

      .MuiDialogActions-root {
        padding: $spacer-6 $spacer-6-5;
        background: $white;
        box-shadow: $shadow-sm;
      }

      #modal-close-button {
        position: absolute;
        top: $spacer-6;
        right: $spacer-6-5;
        height: var(--close-icon-size);
        width: var(--close-icon-size);
        background-color: $medi24-primary;

        .button-icon {
          --button-icon-size: 46px; // 46 represents the button size (40) + the border thickness

          flex: 0 0 auto;
          color: $white;
        }
      }
    }
  }
}

@include tablet-only {
  .MuiDialog-root {
    .MuiDialog-container {
      align-items: center;

      .MuiPaper-root {
        height: fit-content;
        width: calc(100% - #{$spacer-6} * 2);
        max-width: $desktop-width;
        border-radius: $border-radius-xxl;

        .MuiDialogActions-root {
          .button-group {
            flex-direction: row;
            flex: 1;
          }
        }
      }
    }
  }
}

@include desktop-only {
  .MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        .MuiDialogContent-root {
          .classic-text {
            font-size: $font-size-h2;
          }
        }
      }
    }
  }
}
