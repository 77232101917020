.help {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 5;

  .far.fa-question-circle {
    font-size: 30px;
    cursor: pointer;
    color: $medi24-pink;
    margin: 5px;
  }
}

.MuiPopover-root {
  z-index: 4 !important;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 20px;
  height: fit-content;
  box-shadow: 0 0 8px $box-shadow;

  .popover {
    height: 200px;
    width: 250px;
    padding: 45px;

    h1 {
      font-size: 22px;
      margin-bottom: 15px;
    }

    .popover-content {
      height: 60%;
      color: $medi24-gray-3;
      font-size: 16px;

      a {
        white-space: nowrap;
      }
    }

    button {
      height: 45px;
      margin-right: unset;
    }
  }
}

@include tablet-only {
  .help {
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 5;
    top: auto;

    .help-container {
      background-color: $medi24-pink;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      margin: 20px;

      .far.fa-question-circle {
        font-size: 30px;
        cursor: pointer;
        color: white;
        margin: 17%;
      }
    }
  }
}
