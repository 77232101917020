.menu {
  --icon-size: 28px;
  --logout-icon-size: 22px;

  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacer-3;
  background: rgba($white, 0.01);
  backdrop-filter: blur(2px);
  z-index: 5;

  .menu-content {
    display: flex;
    height: $mobile-nav-menu-height;
    width: 100%;
    background-color: $medi24-brown;
    border-radius: $border-radius-md;

    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $spacer-3;
      flex: 1;
      text-decoration: none;

      &, &.selected {
        color: $white;

        .menu-item-icon {
          flex-shrink: 0;
          height: var(--icon-size);
          width: var(--icon-size);
          color: inherit;
        }

        .menu-item-title {
          font-size: $font-size-smaller;
          color: inherit;
          text-align: center;
          font-family: $montserrat-medium;
          line-height: normal;
        }

        &.selected {
          color: $medi24-primary;
        }
      }
    }
  }

  .logout-item {
    display: none;
  }
}

@include tablet-only {
  .menu {
    top: 0;
    right: 0;
    bottom: unset;
    align-items: flex-end;
    gap: $spacer-2;
    width: fit-content;
    padding: 0;
    background: transparent;
    backdrop-filter: unset;

    .menu-content {
      gap: $spacer-5;
      height: fit-content;
      background-color: transparent;
      border-radius: 0;

      .menu-item {
        flex-direction: row;
        gap: $spacer-4;
        flex: 0 0 auto;

        &, &.selected {
          color: $medi24-label-color;
          cursor: pointer;

          .menu-item-title {
            font-size: $font-size-h3;
            font-family: $montserrat-bold;
          }
        }
      }
    }

    .logout-item {
      display: flex;
      align-items: center;
      gap: $spacer-2;
      color: $medi24-primary;
      cursor: pointer;

      .logout-item-title {
        text-align: right;
        font-family: $montserrat-bold;
        font-size: $font-size-h6;
        line-height: normal;
      }

      .logout-item-icon {
        width: var(--logout-icon-size);
        height: var(--logout-icon-size);
      }
    }
  }
}

@include desktop-only {
  .menu {
    gap: $spacer-6;

    .menu-content {
      gap: $spacer-9;
    }
  }
}
