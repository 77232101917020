$base-font-size: 14px;

$left-menu-nav-width: 16rem;
$mobile-nav-menu-height: 70px;
$header-height: 50px;

$content-box-shadow: 0 3px 6px #00000029;

$box-shadow: #00000069;

/* bootstrap override */
$enable-flex: true;

$white: #fff;
$black: #000;

$medi24-primary: #E83363;
$medi24-secondary: #575757;
$medi24-yellow: #F0EA74;
$medi24-dark-yellow: #E2D921;
$medi24-success: #1FBE2F;
$medi24-error: #E83333;
$medi24-blue: #3A9FAC;
$medi24-green: #59AD8A;
$medi24-green-2: #4C9B8A;
$medi24-lighter-pink: #F8EFE9;
$medi24-light-pink: #FCF1F3;
$medi24-light-green: #D4ECCA;
$medi24-dark-green: #3B8F6C;
$medi24-green-flash: #00A811;
$medi24-brown: #2C1C14;

$medi24-red: #e53351;
$medi24-primary-hover: #a31239;
$medi24-pink: #E83363;
$medi24-palepink: #E833631A;
$medi24-secondary-transparent: #57575740;

$medi24-label-color: #424242;

$medi24-gray-1: #F4F4F4;
$medi24-gray-2: #7E7E7E;
$medi24-gray-3: #707070;
$medi24-gray-4: #575757;
$medi24-gray-5: #EFEFEF;
$medi24-gray-6: #F1EFF6;
$medi24-gray-7: #D9D9D9;

$classic-border: 1px solid $medi24-gray-3;
$focus-border: 1px solid $medi24-primary;
$error-border: 1px solid #f44336;

$gray-darker: #2a2d2f;
$gray-dark: #373a3c;
$gray: #55595c;
$gray-medium: #828282;
$gray-light: #818a91;
$gray-medium-light: #A7A7A7;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$red-danger: #d9534f;

$nav-tabs-active-link-hover-bg: #ffffff;

$body-bg: $gray-lighter;

$headings-font-weight: 300;

$font-size-large: 1.71rem;
$font-size-h1: 1.6rem;
$font-size-h2: 1.3rem;
$font-size-h3: 1.15rem;
$font-size-h4: 1.1rem;
$font-size-h5: 1.05rem;
$font-size-h6: 1rem;
$font-size-small: 0.85rem;
$font-size-smaller: 0.75rem;

$desktop-width: 1040px;
$ipad-width: 768px;
$small-phone-width: 400px;

// spacers
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer-3: 0.75rem;
$spacer-4: 1rem;
$spacer-5: 1.5rem;
$spacer-6: 2rem;
$spacer-6-5: 2.25rem;
$spacer-7: 3rem;
$spacer-8: 4rem;
$spacer-9: 5rem;
$spacer-10: 10rem;

// font-families
$montserrat-extra-bold: "Montserrat Extra Bold", Helvetica, Arial, sans-serif;
$montserrat-extra-bold-italic: "Montserrat Extra Bold Italic", Helvetica, Arial, sans-serif;
$montserrat-bold: "Montserrat Bold", Helvetica, Arial, sans-serif;
$montserrat-bold-italic: "Montserrat Bold Italic", Helvetica, Arial, sans-serif;
$montserrat: "Montserrat Regular", Helvetica, Arial, sans-serif;
$montserrat-italic: "Montserrat Italic", Helvetica, Arial, sans-serif;
$montserrat-medium: "Montserrat Medium", Helvetica, Arial, sans-serif;
$montserrat-medium-italic: "Montserrat Medium Italic", Helvetica, Arial, sans-serif;
$montserrat-semi-bold: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
$montserrat-semi-bold-italic: "Montserrat SemiBold Italic", Helvetica, Arial, sans-serif;
$montserrat-light: "Montserrat Light", Helvetica, Arial, sans-serif;
$montserrat-light-italic: "Montserrat Light Italic", Helvetica, Arial, sans-serif;
$montserrat-thin: "Montserrat Thin", Helvetica, Arial, sans-serif;
$montserrat-thin-italic: "Montserrat Thin Italic", Helvetica, Arial, sans-serif;

// border radiuses
$border-radius-md: 1rem;

$app-max-width: 1350px;
$border-radius-xxl: 3rem;
$border-radius-xl: 2rem;
$border-radius-l: 1.5rem;
$border-radius-s: 0.5rem;
$border-radius-xs: 0.25rem;

//shadows
$shadow-sm: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

// layout indexes
$bottom-layout: -1;