@include structuredPage(legal-content);

.page {
  .legal-content-page {
    .legal-content-page-body {
      display: flex;
      flex-direction: column;
      gap: $spacer-5;
      color: $medi24-label-color;

      .content-group {
        display: flex;
        flex-direction: column;
        gap: $spacer-6-5;

        h2,
        .content-title {
          font-family: $montserrat-bold;
          font-size: $font-size-h3;
          line-height: 150%;
        }

        .content-texts-container {
          display: flex;
          flex-direction: column;
          gap: $spacer-5;

          p,
          h4,
          .content-text,
          .content-heading {
            font-family: $montserrat-medium;
            font-size: $font-size-small;
            line-height: 150%;
          }

          h4,
          .content-heading {
            font-family: $montserrat-bold;
          }

          h3,
          .content-subtitle {
            font-family: $montserrat-bold;
            font-size: $font-size-h6;
            line-height: 150%;
            color: $medi24-primary;
          }

          a {
            display: inline-block;
            text-decoration: none;

            &::before, &::after {
              content: '\00a0'; // white space
              display: inline-block;
            }
          }

          ol, li {
            padding: 0;
            margin: 0;
          }

          ol {
            padding-left: $spacer-6-5;
          }
        }
      }
    }
  }
}