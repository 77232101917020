.page {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacer-9;

  .page-content {
    flex: 1;
    width: 100%;
    padding: 0 $spacer-4;

    .separator {
      height: 40px;
    }
  }
}

@include tablet-only {
  .page {
    gap: $spacer-10;

    .page-content {
      padding: 0px $spacer-6;
    }
  }
}

@include desktop-only {
  .page-content {
    max-width: $app-max-width;
  }
}

@include max-width-only {
  .page {
    .page-content {
      padding: 0;
    }
  }
}
