.tile-container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $content-box-shadow;
  padding: 14px;
  position: relative;
  margin-top: 25px;
  height: fit-content;
  min-height: 230px;
  width: 100%;

  button {
    font-size: 14px;
    margin-bottom: unset !important;
    height: 35px;
  }

  .haveDocuments {
    position: absolute;
    top: 10px;
    left: -12px;

    img {
      width: 35px;
    }
  }

  .history-content {
    display: flex;
    height: 100%;

    .informations-history {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100px;
      margin-left: -10px;
      margin-right: 15px;
      .history-img {
        width: 130px;
        margin-top: -15px;
      }
      .referral {
        background-color: $medi24-blue;
        border-radius: 8px;
        color: white;
        font-size: 9px;
        padding: 1px 5px;
        height: fit-content;
        width: fit-content;
        margin-top: -10px;
        margin-bottom: 15px;
      }
      .urgent {
        background-color: $medi24-red;
        border-radius: 8px;
        color: white;
        font-size: 9px;
        padding: 1px 5px;
        height: fit-content;
        width: fit-content;
        margin-top: -10px;
      }
    }

    .tile-history {
      margin-left: -10px;
      margin-top: 20px;
      height: 80%;
      width: 100%;

        .title {
          font-size: 13px;
          text-transform: uppercase;
        }

      .tile-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          margin-bottom: 25px;
          margin-top: 15px;
        }
      }
    }
  }
}

.block-container {
  &.appt {
    display: flex;

    &.triage {
      flex-direction: column;
    }

    &.detail {
      border-bottom: 1px solid $gray-medium-light;
      padding-bottom: 10px;
      margin-top: 10px;

      .classic-text {
        font-size: 12px;
      }
    }
  }
  &.consult-time {
    display: flex;
    justify-content: flex-end;
  }
  &.consult {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-medium-light;
    &:last-child {
      margin-bottom: 20px;
    }
  }
  &.consult:first-child {
    .block {
      margin-top: 0;
    }
  }
  .block {
    margin: 8px 10px 8px 0;
    min-width: 70px;

    &.detail {
      width: 50%;
    }

    .classic-text {
      font-size: 14px;

      &.small {
        font-size: 10px;
      }
    }

    .block-title {
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .block-info {
      font-size: 14px;
      font-weight: bold;

      &.detail {
        font-weight: unset;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}

.remaining {
  font-size: 10px;
  color: $medi24-green-flash;
  margin-top: -5px;

  i {
    margin-right: 5px;
  }

  &.detail {
    margin-top: 2px;
  }
}

.extended {
  font-size: 9px;
  color: $gray-medium-light;
  margin-top: 2px;

  i {
    margin-right: 5px;
  }
}

.detail-history-header {
  display: flex;
  align-items: center;
  .detail-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .referral {
    background-color: $medi24-blue;
    border-radius: 8px;
    color: white;
    font-size: 9px;
    padding: 1px 5px;
    height: fit-content;
    width: fit-content;
    margin-left: 20px;
    margin-bottom: 20px;
    &.consultation {
      margin-bottom: unset;
    }
  }
  .urgent {
    background-color: $medi24-red;
    border-radius: 8px;
    color: white;
    font-size: 9px;
    padding: 1px 5px;
    height: fit-content;
    width: fit-content;
    margin-left: 20px;
    margin-bottom: 20px;
    &.consultation {
      margin-bottom: unset;
    }
  }
}


@include tablet-only {
  .tile-container-content {
    width: 40%;
  }
}

@include desktop-only {
  .tile-container-content {
    width: 28%;
    margin-right: 20px;
  }
}

