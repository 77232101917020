.callback-detail {
  .callback-detail-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 23px;

    .callback-detail-header-title {
      flex-grow: 1;
    }
  }

  .callback-detail-block {
    margin-bottom: 23px;

    .callback-detail-description {
      color: $medi24-gray-4;
      background-color: $medi24-gray-5;
      border: 1px solid $medi24-gray-3;

      .callback-detail-description-text {
        padding: 11px;
        min-height: 132px;
      }
    }

    .input-file-preview-content {
      display: flex;
      align-items: center;
      .input-file-preview-icon {
        color: black;
        font-size: 22px;
        margin-bottom: 2px;
        margin-right: 7px;
      }

      .text-small-small {
        font-size: 12px;
        text-overflow: unset;
        width: fit-content;
        max-width: 100px;
      }
    }
  }

  .contact-button-group {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include tablet-only {
    .button-group {
      justify-content: center;
    }
    .callback-detail-header-row {
      max-width: 50%;

      .small-button {
        margin-right: 0px;
      }
    }
  }
}



