body {
  margin: 0;
}

.container, .content-container, #main-content, .page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  .content-container {
    height: 100%;
    font-family: $montserrat;
    color: $medi24-secondary;

    #main-content {
      height: 100%;

      .content {
        padding: 30px;

        .panel {
          background: #fff;
          border: 2px solid $gray-lighter;
          margin-bottom: 1rem;

          .panel-header {
            background: $gray-lightest;
            border-bottom: 1px solid $gray-lighter;
            font-size: $font-size-h3;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }

          .panel-body {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          .panel-footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
            text-align: center;
            background: $gray-lightest;
            border-top: 1px solid $gray-lighter;
          }
        }
      }

      .background-shape {
        $shape-width: 750px;

        display: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: $bottom-layout;
        width: $shape-width;
      }
    }
  }
}

.hidden {
  display: none;
}

@include tablet-only {
  .container {
    .content-container {
      #main-content {
        .background-shape {
          display: block;
        }
      }
    }
  }
}
