.content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .page-title {
    white-space: pre-line;
    margin-bottom: 15px;
  }

  img {
    width: 250px;
    margin-bottom: -1rem;
  }
}
