.details-popin .MuiDialog-container {
  .MuiPaper-root {
    height: 100%;
    padding: 0;

    .MuiDialogContent-root {
      --hero-height: 210px;
      --illustration-max-height: 190px;
      --colored-block-color: 268px;
      $phone-callback-background: #F9D8AD;
      $urgent-callback-background: #FAD8AD;
      $video-callback-background: #F8EFE9;
      padding: 0;
      margin: 0;

      #modal-close {
        &_details_large, &_details_small {
          position: absolute;
          top: $spacer-6;
          left: $spacer-6-5;
          height: var(--close-icon-size);
          width: var(--close-icon-size);
          background-color: $medi24-primary;

          .button-icon {
            --button-icon-size: 24px;

            flex: 0 0 auto;
            color: $white;
          }
        }

        &_details_large {
          display: none;
        }
      }

      .details-hero {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: var(--hero-height);
        width: 100%;

        img {
          height: 100%;
          max-height: var(--illustration-max-height);
        }

        &--phone {
          background-color: $phone-callback-background;

          &::after {
            background-color: $phone-callback-background;
          }
        }

        &--video, &--consultation {
          background-color: $video-callback-background;

          &::after {
            background-color: $video-callback-background;
          }
        }

        &--appointment {
          background-color: $medi24-light-pink;

          &::after {
            background-color: $medi24-light-pink;
          }
        }

        &--urgent {
          background-color: $urgent-callback-background;

          &::after {
            background-color: $urgent-callback-background;
          }
        }

        &::after {
          position: absolute;
          left: 0;
          top: var(--hero-height);
          content: '';
          display: block;
          height: calc(#{var(--colored-block-color)} - #{var(--hero-height)});
          width: 100%;
        }
      }

      .details-content {
        position: relative;
        height: calc(100% - #{var(--hero-height)});
        width: 100%;
        padding: $spacer-6-5;
        border-top-right-radius: $border-radius-xxl;
        border-top-left-radius: $border-radius-xxl;
        background-color: $white;
        overflow-y: auto;
        overflow-x: hidden;

        .callback-page-header {
          gap: $spacer-4;

          .callback-page-action .button-content {
            font-size: $font-size-small;
          }

          .consultation-date-container {
            display: flex;
            align-items: center;
            gap: $spacer-2;
            opacity: 0.8;

            .callback-data-value {
              font-family: $montserrat-medium;
            }
          }

          .callback-page-subtitle-container {
            flex-wrap: wrap;
          }
        }

        .details-text {
          font-family: $montserrat-bold;
          font-size: $font-size-large;
          color: $medi24-label-color;
        }

        .callback-page-action {
          .callback-data-value {
            display: block;
            height: auto;
            min-height: 0;
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            & + button .button-content {
              font-size: $font-size-small;
              text-wrap: nowrap;
            }
          }
        }

        .callback-page {
          padding: 0;
        }
      }
    }
  }
}

.preview-popin--nested {
  .MuiDialog-container {
    .MuiPaper-root {
      height: 85%;
    }
  }
}

@include mobile-only {
  .details-popin .MuiDialog-container .MuiPaper-root {
    max-height: unset;
    border-radius: unset;
  }
}

@include tablet-only {
  .details-popin .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
    --hero-height: 226px;
    --colored-block-color: 302px;

    #modal-close {
      &_details_small {
        display: none;
      }

      &_details_large {
        right: $spacer-6-5;
        left: unset;
        display: flex;

        .button-icon {
          --button-icon-size: 46px; // 46 represents the button size (40) + the border thickness
        }
      }
    }

    .details-content {
      .callback-page-header {
        gap: $spacer-5;
      }
    }
  }

  .preview-popin--nested {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 100%;
      }
    }
  }
}

@include tablet-only {
  .details-popin .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
    --hero-height: 268px;
    --illustration-max-height: 230px;
  }
}