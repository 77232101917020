.announce-detail-block {
  margin-top: 36px;

  .announce-detail-description {
    color: $medi24-gray-4;
    background-color: $medi24-gray-5;
    border: 1px solid $medi24-gray-3;

    .announce-detail-description-text {
      padding: 11px;
      min-height: 132px;
    }
  }

  .announce-detail-button {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
