.file-popin {
  position: fixed;
  z-index: 1001;
  top: 5%;
  box-shadow: 0px 3px 7px 0px #b5b5b5;
  font-family: $montserrat;
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: white;
  padding: 2%;
  width: 80%;
  max-height: 80%;
  overflow: auto;
  margin: 10px 8%;

  .preview-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .preview-leave {
      font-size: 25px;
      color: $medi24-primary;
      cursor: pointer;
    }
    .preview-download {
      padding-top: 2px;
      font-size: 20px;
      color: $medi24-primary;
      margin-right: 10px;
    }
  }

  .preview-container {
    display: flex;
    justify-content: center;
    .image-preview{
      max-width: 100%;
      max-height: 750px;
      canvas{
        max-width: 100% !important;
        margin-right: 0 !important;
      }
    }

    .pdf-preview{
      width: 100%;
    }
  }
}

.preview-popin .MuiDialog-container .MuiPaper-root {
  .modal-title-container {
    display: flex;
    flex-direction: column-reverse;
    gap: $spacer-6;
    padding: 0 $spacer-6-5;

    .MuiDialogTitle-root {
      padding: 0;
    }

    #modal-close {
      &_desktop,
      &_mobile {
        height: var(--close-icon-size);
        width: var(--close-icon-size);
        background-color: $medi24-primary;

        .button-icon {
          --button-icon-size: 46px; // 46 represents the button size (40) + the border thickness

          flex: 0 0 auto;
          color: $white;
        }
      }

      &_desktop {
        display: none;
      }
    }
  }

  .image-preview {
    width: 100%;
    border-radius: $border-radius-l;
  }
}

@include tablet-only {
  .file-popin{
    top: 10%;
    .preview-container {
      .pdf-preview {
        width: 1000px;
        height: 500px;
      }
    }
  }

  .preview-popin .MuiDialog-container .MuiPaper-root {
    .callback-page {
      padding: $spacer-2 0;
    }

    .modal-title-container {
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: $spacer-5;

      #modal-close {
        &_mobile {
          display: none;
        }

        &_desktop {
          display: flex;

          .button-icon {
            --button-icon-size: 24px;
          }
        }
      }
    }
  }
}
