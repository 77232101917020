.input-file {
  display: flex;
  flex-direction: column;

  .input-file-group {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .MuiInputBase-root {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      width: 140px;
    }

    .input-file-preview-container {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      min-height: 40px;
      margin-bottom: 10px;

      &.detail {
        flex-direction: column;
        min-height: 20px;
        margin-bottom: 10px;

        .input-file-preview-box {
          margin: unset;
        }
      }

      .input-file-preview-box {
        margin: 10px;
        display: flex;
        align-items: center;

        &.detail {
          cursor: unset;
        }

        .input-file-preview-icon {
          color: $medi24-gray-4;
          font-size: 22px;
          margin-bottom: 2px;
          margin-right: 7px;
        }

        .cross {
          margin-left: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: $medi24-primary;
          border-radius: 50%;
          font-size: 10px;
          width: 20px;
          height: 20px;
          .invert {
            filter: invert(100%);
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }

      }
    }
  }

  .button-group {
    width: unset;
    align-items: center;
  }

  .text-small-small {
    color: $medi24-secondary;
    font-size: 10px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90px;
    &.detail {
      width: 70px;
    }
  }

  .input-file-input {
    display: none;
  }
}

@include tablet-only {
  .input-file {
    .input-file-group {
      flex-direction: row;

      .MuiInputBase-root {
        margin-right: 25px;
      }

      .input-file-preview-container {
        margin-bottom: unset;
      }
    }
  }
}

.hidden-input {
  visibility: hidden;
  height: 0;
  width: 0;
}

.data-label-container {
  display: flex;
  align-items: baseline;
}
