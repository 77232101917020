@include structuredPage(signup);

.signup-page {
  .signup-page-choices {
    height: 100%;
    flex-direction: row;
  }

  .signup-data-label {
    text-transform: uppercase;
  }

  #date-input-helper-text {
    display: none;
  }

  .characters-left {
    align-self: end;
  }
}