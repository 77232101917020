@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular Italic'), url('../fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium'), url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium Italic'), url('../fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold'), url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold Italic'), url('../fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Extra Bold'), url('../fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Extra Bold Italic'), url('../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold'), url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold Italic'), url('../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light'), url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light Italic'), url('../fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
}


@font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin'), url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin Italic'), url('../fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

.base-input-coreoz {
  font-size: 16px;
}
