@include structuredPage(feedback);

.feedback-page {
  $star-size: 40px;
  --input-height: 97px;

  align-items: center;

  .stars-container {
    display: flex;
    gap: $spacer-4;

    .star-container {
      position: relative;

      .hidden-button {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        border: unset;
        background-color: unset;
        -webkit-tap-highlight-color: unset;
      }

      .star {
        height: $star-size;
        width: $star-size;
      }
    }
  }

  .feedback-input-group {
    .feedback-input {
      height: var(--input-height);
      resize: none;
    }
  }

  .checkbox-group {
    align-self: flex-start;
  }
}

@include tablet-only {
  .feedback-page {
    --input-height: 168px;
  }
}
