.from-to {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 21px;
  min-height: 40px;


  .title {
    font-size: 10px;
  }

  .date {

  }


  .remaining {
    font-size: 12px;

    i {
      margin-right: 0.05rem;
    }
  }

  .from {
    height: 100%;
  }

  .to {
    height: 100%;

  }


}

@include tablet-only {
  .from-to {
    margin: 0 auto;
    width: 100%;
    font-size: 24px;

    .title {
      font-size: 14px;
    }
  }
}
