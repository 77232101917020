@include structuredPage(profile);

.profile-page {
  .profile-page-group {
    .profile-page-action {
      .profile-data-value {
        min-height: unset;
      }
    }

    .button-group {
      flex-direction: column-reverse;
      gap: $spacer-4;
    }

    .profile-page-inputs {
      .profile-input-group {
        flex: 1;
      }
    }
  }
}

@include tablet-only {
  .profile-page {
    .profile-page-group {
      .button-group {
        gap: $spacer-6;
      }

      .button-group {
        flex-direction: row;
      }
    }
  }
}
