@include structuredPage(history);

.history-events-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: $spacer-4;
}

.history-events-types {
  $badge-height: 40px;
  $badge-min-width: 55px;

  display: flex;
  align-items: center;
  align-self: stretch;
  gap: $spacer-1;

  .event-container {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: $badge-height;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;

    &:not(.event-container--selected) {
      .badge {
        border-color: $medi24-gray-1;
      }
    }

    &--selected .badge {
      border-width: 2px;
    }

    .badge {
      height: $badge-height;
      min-width: $badge-min-width;
      // the border-radius is matching the height to create the pill effect
      border-radius: inherit;
      background-color: $medi24-gray-1;
      color: $medi24-label-color;
    }
  }
}

.history-page {
  .button-group {
    .button--text {
      height: var(--button-height);
    }
  }
}

@include tablet-only {
  .history-events-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include desktop-only {
  .history-events-grid {
    $event-width: 425px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .event {
      width: $event-width;
    }
  }
}
