
.dashboard-title {
  white-space: pre-line;
  margin-bottom: 15px;
}

.tile-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 2rem;

  &.small {
    margin-top: unset;
  }

  .document {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
  }
}
