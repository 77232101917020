.callback-form {
  display: flex;
  flex-direction: column;

  .callback-form-select {
    flex-grow: 1;
    display: flex;

    .MuiFormControl-root {
      flex-grow: 1;
    }
  }

  .MuiSelect-select.MuiSelect-selectMenu {
    white-space: unset;
  }

  .callback-form-block:first-child {
    margin-top: 30px;
  }

  .callback-form-block {
    .callback-form-block-title {
      margin-bottom: 20px;
      font-weight: bold;
    }

    .classic-text {
      text-align: unset;
    }

    .callback-form-input-group {
      width: 100%;
      display: flex;
      flex-direction: column;

      .characters-left {
        text-align: end;
        color: $gray-medium-light;
        margin-top: 5px;
      }

      .classic-text {
        display: inline-block;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .callback-form-small-inputs {
        margin-bottom: 20px;
      }
    }

    .callback-form-row-item {
      position: relative;

      .callback-form-input {
        .registration-description {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      .error-text {
        position: absolute;
        top: 42px;
      }
    }

    .callback-form-row-item {
      margin-bottom: 1.2rem;
    }

    .input-file {
      margin-bottom: 30px;
    }
  }

  .callback-form-date-picker {
    display: flex;
    align-items: center;
    position: relative;

    .callback-form-date-picker-icon {
      color: $medi24-primary;
      font-size: 22px;
      position: absolute;
      right: 16px;
    }

    .MuiFormControl-root {
      flex-grow: 1;
    }
  }

  .callback-form-input-file {
    .no-image {
      display: none;
    }

    .image {
      width: 50px;
    }
  }

  .callback-form-row {
    display: flex;
    flex-direction: column;

    .callback-form-row-item {
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
  }

  .base-error-form {
    left: 0;
  }
}

@include tablet-only {
  .callback-form {
    .MuiPickersBasePicker-container {
      justify-content: center;
      overflow: hidden;
      margin-bottom: -10px;
    }


    .button-group {
      justify-content: center;

      button {
        align-self: unset;

        &.button.primary {
          margin-right: 10px;
        }
      }
    }

    .callback-form-input-group {
      .description-input {
        margin-top: 30px;
      }

      .callback-form-small-inputs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .callback-form-input {
          width: 49%;
        }
      }

      .callback-form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .callback-form-row-item {
          width: calc(50% - 1rem);
          justify-content: flex-end;
        }

        .callback-form-row-item {
          justify-content: start;
        }
      }
    }
  }
}


@include structuredPage(callback);

.callback-page {
  .characters-left {
    width: 100%;
    text-align: end;
    font-size: $font-size-small;
  }
}

@include desktop-only {
  .callback-page {
    .characters-left {
      font-size: $font-size-h6;
    }
  }
}
