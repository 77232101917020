.featured-tiles {
  display: grid;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacer-5;


  .featured-tile {
    --icon-size: 50px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacer-4;
    padding: $spacer-5;
    color: $medi24-primary;
    background-color: white;
    border: 2px solid $medi24-gray-1;
    border-radius: 16px;

    &--primary {
      color: white;
      background-color: $medi24-primary;
      border: unset;
    }

    &--secondary {
      background-color: $medi24-gray-1;
      border: unset;
    }

    .featured-tile-content {
      text-align: center;
      font-size: $font-size-h3;
      font-style: normal;
      font-weight: 600;
      line-height: 128%;
    }

    .featured-tile-icon {
      height: var(--icon-size);
      width: var(--icon-size);

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .hidden-button {
      all: unset;
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      border-radius: inherit;
      cursor: pointer;
    }
  }
}

@include tablet-only {
  .featured-tiles {
    --tile-width: 200px;

    justify-content: space-between;
    grid-template-columns: auto auto auto;

    .featured-tile {
      width: var(--tile-width);
    }
  }
}

@include desktop-only {
  .featured-tiles {
    --tile-width: 234px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}