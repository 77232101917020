html {
  font-family: $montserrat;
  font-size: $base-font-size;
}

a,
button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}
